import { PRODUCTS_SERVICE } from "@/services/products.service.js";


const state = { listOutput: null, productOutput: {}};

const actions = {
    getList({ dispatch, commit }, inputGetProducts) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        PRODUCTS_SERVICE.list(inputGetProducts)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    console.log(error)
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getDetail({ dispatch, commit }, slug) {
        dispatch('alert/clear', null, { root: true });
        commit('getProductSlugRequest');
        
        PRODUCTS_SERVICE.getDetail(slug)
        .then(
            output => {
                commit('getProductSlugSuccess', output);
            },
            error => {
                    commit('getProductSlugFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* List */
    listRequest(state) {
        state.listOutput = null;
    },
    listSuccess(state, output) {
        console.log(output)
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = false;
    },
    /* getProductDetail */
    getProductSlugRequest() {
        state.productOutput = null;
    },
    getProductSlugSuccess(state, output) {
        state.productOutput = output;
    },
    getProductSlugFailure(state) {
        state.productOutput = false;
    },
};


export const products = {
    namespaced: true,
    state,
    actions,
    mutations
};