const state = {
    type: null,
    output: null
};

const actions = {
    success({ commit }, output) {
        commit('success', output);
    },
    error({ commit }, output) {
        commit('error', output);
    },
    clear({ commit }, output) {
        commit('clear', output);
    }
};

const mutations = {
    success(state, output) {
        state.type = 'alert-success';
        state.output = output;
    },
    error(state, output) {
        state.type = 'alert-danger';
        state.output = output;
    },
    clear(state, output) {
        state.type = null;
        state.output = output;
    }
};

export const alert = {
    namespaced: true,
    state,
    actions,
    mutations
};
