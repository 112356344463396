<template>
  <section>
    <SpinnerLoading v-if="productsOutput == null"/>
    <div class="container-fluid" v-else>
        <div class="row">
            <div class="col-12 col-lg-4 col-content">
              <span class="title-style">{{$t("home.title")}} <span class="doorgate-title-style">{{$t("home.doorgate")}}</span></span>
              <span class="content-style">{{$t("home.subTitle")}}</span>
              <button @click="sendClick()" class="btn btn-style">{{$t("home.startTour")}}</button>
            </div>
            <div class="col-12 col-lg-8">
                <!-- <img class="w-100 h-75" src="../assets/home/homeV2.jpg" alt=""> -->
                <iframe width="100%" height="700px" :src="embedLink(videoUrlPath) + '?rel=0'" title="YouTube video player" controls=0 frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
        <SliderHome :products="productsList"></SliderHome>
    </div>
  </section>
</template>

<script>
import SpinnerLoading from "@/components/SpinnerLoading.vue";
import SliderHome from "../components/SliderHome.vue"
import { mapState, mapActions } from "vuex";
import InputGetList from "@/classes/InputGetList";
export default {
  components:{
    SliderHome,
    SpinnerLoading
  },
  data() {
    return {
      videoUrl: 'https://www.youtube.com/watch?v=TAS8getd0yo'
    }
  },
  computed: {
    ...mapState({
        productsOutput: state => state.products.listOutput,
    }),
    productsList: function() {
      return this.productsOutput && this.productsOutput.data ? this.productsOutput.data : [];
    },
    videoUrlPath: function()
    {
      return this.videoUrl ? this.videoUrl : null;
    },
  },
  methods: {
    ...mapActions({
        getProducts: 'products/getList',
    }),
    invokeGetInfo()
    {
        const itemsPerPage = 999;
        const currentPage = 1;
        const searchText = '';
        const sorter = 2; // desc
        const propertyName = "id";

        var input = new InputGetList(itemsPerPage, currentPage, searchText, sorter, propertyName);
        // List specific parameters
        // input.visible= true;
        this.getProducts(input);
    },
    embedLink: function (link) {
      let genericUrl = "https://www.youtube.com/watch?v=";
      let replaceUrl = "https://www.youtube.com/embed/";

      if(link && genericUrl){
          return link.replace(genericUrl, replaceUrl); 
      }else{
          return link;
      }
    },
    sendClick(){
      this.$router.push({name: "Categories",});
    },
  },
  mounted() {
     // Get info from API
    this.invokeGetInfo();
  },
}
</script>

<style scoped>
a{
  text-decoration: none !important;
  color: black !important;
  cursor: pointer;
}
.col-content{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.title-style{
  word-break: break-word;
  color: gray;
  font-size: 72px;
}
.doorgate-title-style{
  color: var(--primary-color);
  font-weight: bold;
  font-size: 72px;
}
.content-style{
  color: gray;
  margin-top: 1rem;
}
.btn-style{
  width: 150px;
  height: 50px;
  color: white;
  background-color: var(--primary-color);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}
@media only screen and (max-width: 1450px) {
  .title-style{
    font-size: 42px;
  }
  .doorgate-title-style{
    font-size: 42px;
  }
}
@media only screen and (max-width: 991px) {
  .col-content{
    margin-bottom: 2rem;
  }
}
</style>