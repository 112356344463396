<template>
   <!-- Slider -->
      <div v-if="products.length" class="col-12 mt-5 d-flex align-items-center" id="slide">
        <div class="d-flex arrow-left cursor-pointer">
          <img class="arrow-style" @click="$refs.homeSlider.previous()" src="../assets/home/arrow-left.svg" alt="" />
        </div>
        <vueper-slides ref="homeSlider" :arrows="false" class="slider no-shadow w-100" fixed-height= "400px" :bullets="false" :bullets-outside="true" :visible-slides="3" :gap="1" :touchable="false" :dragging-distance="70" :slide-ratio="1" :breakpoints=" {
            1399: { visibleSlides: 2 },
            768: { visibleSlides: 1, touchable:true, bullets: true, bulletsOutside: true },
          }">
          <vueper-slide v-for="(product,index) in products" :key="product.id">
            <template v-slot:content>
                <div class="card-style">
                    <span class="title-card">{{product.name}}</span>
                    <span class="item-card flex-grow-1" v-html="addEllipsis(product.excerpt)"></span>
                    <!-- <hr> -->
                    <button @click="sendClick(index)" class="btn btn-style">{{$t("learnMore")}}</button>
                </div>
            </template>
          </vueper-slide>
        </vueper-slides>  
        <div class="d-flex arrow-right cursor-pointer">
          <img class="arrow-style" @click="$refs.homeSlider.next()" src="../assets/home/arrow-right.svg" alt="" />
        </div>
      </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import { UTILS_HELPER } from "@/helpers/utils.js"
import "vueperslides/dist/vueperslides.css";
export default {
  components:{
    VueperSlides, 
    VueperSlide
  },
  props: {
    products: {
      type: Array,
    },
  },
  methods: {
    sendClick(index){
      this.$router.push({name: "ProductDetails" , params:{ slug: this.products[index].slug}});
    },
    addEllipsis(html) {
      const maxLength = 350;
      var text = UTILS_HELPER.htmlToText(html);
      if (text.length <= maxLength) {
        return text;
      } else {
        return text.slice(0, maxLength) + "...";
      }
    }
  }
}
</script>

<style scoped>
/* .slider{
    height: 500px;
} */
.card-style{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    height: 400px;
}
.title-card{
    font-size: 30px;
}
.item-card{
    font-size: 20px;
}
.arrow-style{
    width: 70px;
    height: 70px;
    padding: 0 10px;
}
.btn-style{
  width: 150px;
  height: 50px;
  color: white;
  background-color: var(--primary-color);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}
hr{
    border: 1px solid gray !important;
    width: 100%;
}
.arrow-left{
  transform: rotate(180deg);
}
@media (max-width: 576px) {
  .arrow-style{
    display: none;
}
}
</style>