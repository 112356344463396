import Vue from "vue";
import Vuex from "vuex";

import { alert} from '@/store/alert.module';
import { categories} from '@/store/categories.module';
import { staticPages} from '@/store/staticPages.module';
import { products} from '@/store/products.module';
import { videos} from '@/store/videos.module';
import { account} from '@/store/account.module';


Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    alert,
    categories,
    staticPages,
    products,
    videos,
    account
  },
});
