<template>
    <section id="login">
        <div class="container d-flex flex-column justify-content-center">
            <div class="row mt-5">
                <div class="col-12 d-flex justify-content-center">
                    <h4 v-if="action !== RECOVERACTION " class="register-style cursor-pointer" v-bind:class="{ 'active': action == REGISTERACTION }" @click="action = REGISTERACTION">{{ $t("login.register") }}</h4>
                    <div v-if="action !== RECOVERACTION " class="vertical-line"></div>
                    <h4 v-if="action !== RECOVERACTION " class="login-style cursor-pointer" v-bind:class="{ 'active': action == LOGINACTION }" @click="action = LOGINACTION">{{ $t("login.login") }}</h4>
                    <h4 v-if="action == RECOVERACTION" class="cursor-pointer" v-bind:class="{ 'active': action == RECOVERACTION }">{{ $t("login.recover") }}</h4>
                </div>
            </div>
            <!-- Form -->
            <form @submit.prevent="submitClicked">
                <div class="row mt-5">
                    <!-- <div class="col-12 text-center">
                        <span>{{ $t("login.description") }}</span>
                    </div> -->
                </div>
                <div class="row mt-5 mb-5">
                    <div v-if="action == REGISTERACTION" class="col-12 form-group my-2">
                        <label for="firstName">{{ $t("login.firstName") }}</label>
                        <input type="text" v-model="firstName" id="firstName" name="firstName" class="form-control" :class="{ 'is-invalid': submitted && hasError('FirstName') }" />
                        <div v-if="submitted && hasError('FirstName')" class="invalid-feedback">
                            <span v-if="!$v.firstName.required">{{ $t("login.firstNameVal") }}</span>
                            <span v-if="errors.FirstName">{{errors.FirstName}}</span>
                        </div>
                    </div>
                    <div v-if="action == REGISTERACTION" class="col-12 form-group my-2">
                        <label for="lastName">{{ $t("login.lastName") }}</label>
                        <input type="text" v-model="lastName" id="lastName" name="lastName" class="form-control" :class="{ 'is-invalid': submitted && hasError('LastName') }" />
                        <div v-if="submitted && hasError('LastName')" class="invalid-feedback">
                            <span v-if="!$v.lastName.required">{{ $t("login.lastNameVal") }}</span>
                            <span v-if="errors.LastName">{{errors.LastName}}</span>
                        </div>
                    </div>
                    <div class="col-12 form-group my-2">
                        <label for="email">{{ $t("login.email") }}</label>
                        <input type="text" id="email" name="email" v-model="email" class="form-control" invalid-feedback="E-mail Inválido" :class="{ 'is-invalid': submitted && hasEmailError }"/>
                        <div v-if="submitted && hasEmailError" class="invalid-feedback">
                            <span v-if="!$v.email.required">{{ $t("login.emailVal") }}</span>
                            <span v-if="!$v.email.email">{{ $t("login.invEmail") }}</span>
                            <span v-if="errors.Email">{{errors.Email}}</span>
                            <span v-if="errors.DuplicateEmail">{{errors.DuplicateEmail}}</span>
                            <span v-if="errors.InvalidEmail">{{errors.InvalidEmail}}</span>
                            <span v-if="errors.Username">{{errors.Username}}</span>
                        </div>
                    </div>
                    <div v-if="action == REGISTERACTION" class="col-12 form-group my-2">
                        <label for="phoneNumber">{{ $t("login.phoneNumber") }}</label>
                        <input type="number" id="phoneNumber" name="phoneNumber" v-model="phoneNumber" class="form-control" invalid-feedback="E-mail Inválido"/>
                        <!-- <div v-if="submitted && hasError('PhoneNumber')" class="invalid-feedback">
                            <span v-if="!$v.phoneNumber.required">{{ $t("login.phoneNumberVal") }}</span>
                            <span v-if="errors.PhoneNumber">{{errors.PhoneNumber}}</span>
                        </div>  -->
                    </div>
                    <div v-if="action == REGISTERACTION" class="col-12 form-group my-2">
                        <label for="companyName">{{ $t("login.companyName") }}</label>
                        <input type="text" v-model="companyName" id="companyName" name="companyName" class="form-control" :class="{ 'is-invalid': submitted && hasError('CompanyName') }" />
                        <div v-if="submitted && hasError('CompanyName')" class="invalid-feedback">
                            <span v-if="!$v.companyName.required">{{ $t("login.companyNameVal") }}</span>
                            <span v-if="errors.CompanyName">{{errors.CompanyName}}</span>
                        </div>
                    </div>
                    <div v-if="action == REGISTERACTION" class="col-12 form-group my-2">
                        <label for="companyAddress">{{ $t("login.companyAddress") }}</label>
                        <input type="text" v-model="companyAddress" id="companyAddress" name="companyAddress" class="form-control" :class="{ 'is-invalid': submitted && hasError('CompanyAddress') }" />
                        <div v-if="submitted && hasError('CompanyAddress')" class="invalid-feedback">
                            <span v-if="!$v.companyAddress.required">{{ $t("login.companyAddressVal") }}</span>
                            <span v-if="errors.CompanyAddress">{{errors.CompanyAddress}}</span>
                        </div>
                    </div>
                    <div v-if="action == REGISTERACTION" class="col-12 form-group my-2">
                        <label for="companyZipCode">{{ $t("login.companyZipCode") }}</label>
                        <input type="number" v-model="companyZipCode" id="companyZipCode" name="companyZipCode" class="form-control" :class="{ 'is-invalid': submitted && hasError('CompanyZipCode') }" />
                        <div v-if="submitted && hasError('CompanyZipCode')" class="invalid-feedback">
                            <span v-if="!$v.companyZipCode.required">{{ $t("login.companyZipCodeVal") }}</span>
                            <span v-if="errors.CompanyZipCode">{{errors.CompanyZipCode}}</span>
                        </div>
                    </div>
                    <div v-if="action == REGISTERACTION" class="col-12 form-group my-2">
                        <label for="companyCity">{{ $t("login.companyCity") }}</label>
                        <input type="text" v-model="companyCity" id="companyCity" name="companyCity" class="form-control" :class="{ 'is-invalid': submitted && hasError('CompanyCity') }" />
                        <div v-if="submitted && hasError('CompanyCity')" class="invalid-feedback">
                            <span v-if="!$v.companyCity.required">{{ $t("login.companyCityVal") }}</span>
                            <span v-if="errors.CompanyCity">{{errors.CompanyCity}}</span>
                        </div>
                    </div>
                    <div v-if="action == REGISTERACTION" class="col-12 form-group my-2">
                        <label for="companyCountry">{{ $t("login.companyCountry") }}</label>
                        <input type="text" v-model="companyCountry" id="companyCountry" name="companyCountry" class="form-control" :class="{ 'is-invalid': submitted && hasError('CompanyCountry') }" />
                        <div v-if="submitted && hasError('CompanyCountry')" class="invalid-feedback">
                            <span v-if="!$v.companyCountry.required">{{ $t("login.companyCountryVal") }}</span>
                            <span v-if="errors.CompanyCountry">{{errors.CompanyCountry}}</span>
                        </div>
                    </div>
                    <div v-if="action == REGISTERACTION" class="col-12 form-group my-2">
                        <label for="companyWebsite">{{ $t("login.companyWebsite") }}</label>
                        <input type="text" v-model="companyWebsite" id="companyWebsite" name="companyWebsite" class="form-control" />
                        <!-- <div v-if="submitted && hasError('CompanyWebsite')" class="invalid-feedback">
                            <span v-if="!$v.companyWebsite.required">{{ $t("login.companyWebsiteVal") }}</span>
                            <span v-if="errors.CompanyWebsite">{{errors.CompanyWebsite}}</span>
                        </div> -->
                    </div>
                     <div v-if="action == LOGINACTION" class="col-12 form-group my-2">
                        <label for="password">{{ $t("login.pass") }}</label>
                        <input type="password" id="password" name="password" v-model="password" class="form-control" invalid-feedback="Password Inválida" :class="{ 'is-invalid': submitted && hasPasswordError }"/>
                        <div v-if="submitted && hasPasswordError" class="invalid-feedback">
                            <span v-if="!$v.password.required">{{ $t("login.passVal") }}</span>
                            <span v-if="errors.Password">{{errors.Password}}</span>
                            <span v-if="errors.PasswordTooShort">{{errors.PasswordTooShort}}</span>
                            <span v-if="errors.PasswordRequiresNonAlphanumeric">{{errors.PasswordRequiresNonAlphanumeric}}</span>
                            <span v-if="errors.PasswordRequiresDigit">{{errors.PasswordRequiresDigit}}</span>
                            <span v-if="errors.PasswordRequiresLower">{{errors.PasswordRequiresLower}}</span>
                            <span v-if="errors.PasswordRequiresUpper">{{errors.PasswordRequiresUpper}}</span>
                        </div>
                    </div>
                   <!-- <div v-if="action == REGISTERACTION" class="col-12 form-group my-2">
                        <label for="confirmPassword">{{ $t("login.rpass") }}</label>
                        <input type="password" v-model="confirmPassword" id="confirmPassword" name="confirmPassword" class="form-control" :class="{ 'is-invalid': submitted && hasError('ConfirmPassword') }" />
                        <div v-if="submitted && hasError('ConfirmPassword')" class="invalid-feedback">
                            <span v-if="!$v.confirmPassword.required">{{ $t("login.valPass") }}</span>
                            <span v-else-if="!$v.confirmPassword.sameAsPassword">{{ $t("login.matchPass") }}</span>
                        </div>
                    </div> -->
                    <div class="row m-0 p-0" v-if="action == LOGINACTION">
                        <div class="d-flex justify-content-between">
                            <p class="cursor-pointer" id="recover-style" @click="action = RECOVERACTION">{{ $t("login.forgotPass") }}</p>
                            <!-- Only appears if the user try to login and the account is not valid. -->
                            <router-link v-if="errors.Username == 'A conta não está validada.' " id="recover-style" :to="{name: 'ConfirmAccount'}">{{ $t("login.resendConfirmAccount") }}</router-link>
                        </div>
                    </div>
                    <div class="row" v-if="action == RECOVERACTION">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                            <p class="cursor-pointer" id="recover-style" @click="action = LOGINACTION">{{ $t("login.loginBack") }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 mb-5 text-center">
                    <button type="submit" class="btn btn-secondary" v-bind:class="{disabled: loading}" :style="{minWidth: '140px'}">
                        <span v-if="action == REGISTERACTION" v-show="!loading">{{$t("login.registerBtn")}}</span>
                        <span v-else-if="action == LOGINACTION" v-show="!loading">{{$t("login.loginBtn")}}</span>
                        <span v-else-if="action == RECOVERACTION" v-show="!loading">{{$t("login.recoverButton")}}</span>
                        <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
                    </button> 
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { UTILS_HELPER } from "@/helpers/utils.js"
import { required, email, sameAs, minLength } from 'vuelidate/lib/validators'
import { API_HELPER } from "@/helpers/api.js";

const LOGINACTION = 0;
const REGISTERACTION = 1;
const RECOVERACTION = 2;

export default {
    // metaInfo () {
    //   return { 
    //         title: `${this.$t('vueRouter.loginTitle')} - Doorgate Fair`, 
    //         htmlAttrs: {
    //             lang: this.$i18n.locale,
    //         },
    //         link: [
    //             {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.login", 'en')])},
    //             {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.login", 'pt')])},
    //             {rel: 'alternate', hreflang: 'es', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.login", 'es')])},
    //         ]
    //     } 
    // },
    data: function () {
        return {
            action: LOGINACTION,
            // form
            firstName: null,
            lastName: null,
            email: null,
            phoneNumber: null,
            companyName: null,
            companyAddress: null,
            companyZipCode: null,
            companyCity: null,
            companyCountry: null,
            companyWebsite: null,
            password: null,
            confirmPassword: null,
            submitted: false,
            // Expose for HTML
            LOGINACTION: LOGINACTION,
            REGISTERACTION: REGISTERACTION,
            RECOVERACTION: RECOVERACTION,
            loading: false,
        };
    },
    validations() {
        switch(this.action) 
        {
            case LOGINACTION:
            {
                return {
                    email: { required, email },
                    password: { required }
                }
            }
            case REGISTERACTION:
                {
                    return {
                        firstName: { required },
                        lastName: { required },
                        email: { required, email },
                        // phoneNumber: { required },
                        companyName: { required },
                        companyAddress: { required },
                        companyZipCode: { required },
                        companyCity: { required },
                        companyCountry: { required },
                        // companyWebsite: { required },
                        // password: { required },
                        // confirmPassword: { required, sameAsPassword: sameAs('password') }
                    }
            }
            case RECOVERACTION:
            {
                return {
                    email: { required, email }
                }
            }
        } 
    },
    computed:
    {
        ...mapState({ 
        alertType: state => state.alert.type,
        alertOutput: state => state.alert.output
        }),
        ...mapState('account', ['reset']),
        ...mapState('account', ['status']),
        errors: function()
        {
            return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};   
        },
        hasPasswordError()
        {
            return this.$v[UTILS_HELPER.convertToCamelCase("Password")].$error || Object.prototype.hasOwnProperty.call(this.errors, "Password") || Object.prototype.hasOwnProperty.call(this.errors, "PasswordTooShort") || Object.prototype.hasOwnProperty.call(this.errors, "PasswordRequiresNonAlphanumeric") || Object.prototype.hasOwnProperty.call(this.errors, "PasswordRequiresDigit") || Object.prototype.hasOwnProperty.call(this.errors, "PasswordRequiresLower") || Object.prototype.hasOwnProperty.call(this.errors, "PasswordRequiresUpper");
        },
        hasEmailError()
        {
            return (this.$v['email'] && this.$v['email'].$error) || Object.prototype.hasOwnProperty.call(this.errors, "Email") || Object.prototype.hasOwnProperty.call(this.errors, "InvalidEmail") || Object.prototype.hasOwnProperty.call(this.errors, "DuplicateEmail") || Object.prototype.hasOwnProperty.call(this.errors, "Username");
        }
    },
      methods: {
    ...mapActions('account', ['login']),
    ...mapActions('account', ['signup']),
    ...mapActions('account', ['requestLink']),
    ...mapActions({
        clearAlert: 'alert/clear' 
    }),
    submitClicked: function()
    {
        switch(this.action)
        {
            case LOGINACTION: { this.loginClicked(); break; }
            case REGISTERACTION: { this.registerClicked(); break; }
            case RECOVERACTION: { this.forgotPasswordClicked(); break; }
        }
    },
    loginClicked: function(){
        this.clearAlert();
        this.submitted = true;
        // stop here if form is invalid
        console.log(this.$v)
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }
        this.loading = true;

        const { email, password } = this;
        this.login({ email, password })
    },
    forgotPasswordClicked: function()
    {
        this.clearAlert();
        this.submitted = true;
        // stop here if form is invalid
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }
    
        this.loading = true;
        this.requestLink(this.email);
    },
    registerClicked: function()
    {
        this.clearAlert();
        this.submitted = true;

        //stop here if form is invalid
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }

        this.loading = true;
        
        const { firstName, lastName, email, phoneNumber, companyName, companyAddress, companyZipCode, companyCity, companyCountry, companyWebsite} = this;
        this.signup({ firstName, lastName, email, phoneNumber, companyName, companyAddress, companyZipCode, companyCity, companyCountry, companyWebsite})

    },
    hasError(fieldName)
    {
      return this.$v[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
    }
  },
    watch:{
        action: function()
        {
            this.submitted = false;
        },
        alertType: function(val)
        {
            this.loading = val === null;
        },
        status: function(val)
        {
            if(val)
                {
                    if(val.registered)
                        {
                            this.$fire({
                                title: this.$t("login.registerPopup"),
                                text: this.$t("login.registerText"),
                                type: "info"
                            })
                            this.submitted = false;

                            this.$router.push({name: 'Home'})
                        }
                }
                else
                    {
                        this.$fire({
                            title: this.$t("login.loginError"),
                            // text: this.$t("login.registerText"),
                            type: "warning"
                        })
                        this.submitted = false;
                        this.loading = false;
                    }
        },
        reset: function(val)
        {
            if(val)
            {
                this.loading = false;
                this.$fire({
                    title: this.$t("login.registerPopup"),
                    // text: this.$t("login.forgotText"),
                    type: "success"
                })
                this.submitted = false;
                this.email = null;
                this.$router.push({name: 'SetPassword'})
            }
        }
    }
}
</script>

<style scoped>
.container{
    max-width: 500px!important;
    /* min-height: 100vh; */
}
.register-style{
    margin-right: 2rem;
}
.login-style{
    margin-left: 2rem;
}
.vertical-line{
    border-right: 2px solid var(--primary-color);
}
h4.active{
    color: var(--primary-color);
    font-weight: bold;
}
#recover-style{
    text-decoration: underline;
    color: var(--primary-color);
}
</style>