<template>
  <section>
    <SpinnerLoading v-if="productsOutput == null"/>
    <div class="container-fluid" v-else>
        <div class="row">
            <div class="col-12 col-md-8">
              <img v-if="imageCategory" class="w-100" :src="imageCategory" alt="">
              <img v-else class="w-100" src="../assets/institutional.gif" alt="">
            </div>
            <div class="col-12 col-md-4">
              <div class="col-12">
                <div class="d-flex mt-3">
                  <img class="cursor-pointer" @click="$router.back()" src="../assets/stand/arrow-left.svg" alt="">
                  <span class="categories-title mx-3">{{$t("products")}}</span>
                </div>
                <br>
                <!-- <h5>{{productsList}}</h5> -->
                <ul class="no-marker" v-for="(product, index) in productsList" :key="index">
                  <a @click="sendClick(index)">
                    <li>
                      <img src="../assets/stand/hotspots.svg" alt="">
                      <span class="mx-3">{{product.name}}</span>
                    </li>
                  </a>
                </ul>
              </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import SpinnerLoading from "@/components/SpinnerLoading.vue";
import { mapState, mapActions } from "vuex";
import InputGetProducts from "@/classes/InputGetProducts";
import { API_HELPER } from "@/helpers/api.js";
export default {
  components:{
    SpinnerLoading
  },
  data() {
    return {
      categoryId: this.$route.query.id,
    }
  },
  computed: {
    ...mapState({
        productsOutput: state => state.products.listOutput,
    }),
    productsList: function() {
      return this.productsOutput && this.productsOutput.data ? this.productsOutput.data : [];
    },
    imageCategory: function() {
      return this.$route.params && this.$route.params.image ? this.$route.params.image : null;
    },
  },
  methods: {
    ...mapActions({
        getProducts: 'products/getList',
    }),
    invokeGetInfo()
    {
        const itemsPerPage = 999;
        const currentPage = 1;
        const searchText = '';
        const sorter = 2; // desc
        const propertyName = "id";
        const categoryId = this.categoryId;

        var input = new InputGetProducts(itemsPerPage, currentPage, searchText, sorter, propertyName, categoryId);
        // List specific parameters
        // input.visible= true;
        this.getProducts(input);
    },
    sendClick(index){
      this.$router.push({name: "ProductDetails" , params:{ slug: this.productsList[index].slug}});
    },
  },
  mounted() {
     // Get info from API
    this.invokeGetInfo();
  },
}
</script>

<style scoped>
a{
  text-decoration: none !important;
  color: black !important;
  cursor: pointer;
}

img
{
  max-height: 50vh;
  object-fit: contain;
}

.no-marker {
  list-style-type: none;
}

.categories-title{
  font-size: 30px;
  font-weight: bold;
}
</style>