export default class InputGetProducts
{
  constructor(pageSize, pageNumber, searchText, orderBy, propertyName, categoryId) 
  {
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
    this.searchText = searchText;
    this.orderBy = orderBy;
    this.propertyName = propertyName;
    this.categoryId = categoryId;
  }    
}