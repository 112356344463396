<template>
  <section>
    <SpinnerLoading v-if="categoriesOutput == null"/>
    <div class="container-fluid" v-else>
        <div class="row">
           <div class="col-12 col-md-8">
            <video v-if="videoCategory" width="100%" autoplay>
              <source :src="videoCategory" type="video/mp4">
            Your browser does not support the video tag.
            </video>
              <img v-else-if="imageCategory" class="w-100" :src="imageCategory" alt="">
              <img v-else class="w-100" src="../assets/institutional.gif" alt="">
            </div>
            <div class="col-12 col-md-4">
                <div class="d-flex">
                  <img class="cursor-pointer" @click="$router.back()" src="../assets/stand/arrow-left.svg" alt="">
                  <span class="categories-title my-3 mx-3">{{$t("subCategories")}}</span>
                </div>
                <br>
                <span class="mx-3" v-if="categoriesList && categoriesList.length == 0">{{$t("noResults")}}</span>
                <ul class="no-marker" v-for="(categorie, index) in categoriesList" :key="index">
                  <a @click="sendClick(index)">
                    <li>
                      <img src="../assets/stand/hotspots.svg" alt="">
                      <span class="mx-3">{{categorie.title}}</span>
                    </li>
                  </a>
                </ul>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import SpinnerLoading from "@/components/SpinnerLoading.vue";
import { mapState, mapActions } from "vuex";
import InputGetSubCategory from "@/classes/InputGetSubCategory";
import { API_HELPER } from "@/helpers/api.js";
export default {
  components:{
    SpinnerLoading
  },
  data() {
    return {
      parentId: this.$route.query.id,
    }
  },
  computed: {
    ...mapState({
        categoriesOutput: state => state.categories.listOutput,
    }),
    categoriesList: function() {
      return this.categoriesOutput && this.categoriesOutput.data ? this.categoriesOutput.data : [];
    },
    imageCategory: function() {
      return this.$route.params && this.$route.params.image ? this.$route.params.image : null;
    },
    videoCategory: function() {
      return this.$route.params && this.$route.params.video ? this.$route.params.video : null;
    },
  },
  methods: {
    ...mapActions({
        getCategories: 'categories/getList',
    }),
    invokeGetInfo()
    {
        const itemsPerPage = 999;
        const currentPage = 1;
        const searchText = '';
        const sorter = 2; // desc
        const propertyName = "id";
        const parentId = this.parentId;

        var input = new InputGetSubCategory(itemsPerPage, currentPage, searchText, sorter, propertyName, parentId );
        // List specific parameters
        // input.visible= true;
        this.getCategories(input);
    },
    sendClick(index){
      this.$router.push({name: "Products", query: {id: this.categoriesList[index].id}, params: {image: this.getImageCategory(index)} });
    },
    getImageCategory(index)
    {
      return this.categoriesList[index] && this.categoriesList[index].photoPath ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.categoriesList[index].photoPath]) : null;
    }
  },
  mounted() {
     // Get info from API
    this.invokeGetInfo(this.routeId);
  },
}
</script>

<style scoped>
a{
  text-decoration: none !important;
  color: black !important;
  cursor: pointer;
}
img
{
  object-fit: contain;
  max-height: 50vh;
}
.no-marker {
  list-style-type: none;
}
.categories-title{
  font-size: 30px;
  font-weight: bold;
}
</style>