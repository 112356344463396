import { VIDEOS_SERVICE } from "@/services/videos.service.js";


const state = { listOutput: null, videoDetailsOutput: {}};

const actions = {
    getList({ dispatch, commit }, inputGetVideos) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        VIDEOS_SERVICE.list(inputGetVideos)
        .then(
            output => {
                commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getDetail({ dispatch, commit }, slug) {
        dispatch('alert/clear', null, { root: true });
        commit('getVideosSlugRequest');
        
        VIDEOS_SERVICE.getDetail(slug)
        .then(
            output => {
                commit('getVideosSlugSuccess', output);
            },
            error => {
                    commit('getVideosSlugFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* List */
    listRequest(state) {
        state.listOutput = null;
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = false;
    },
    /* getVideosDetail */
    getVideosSlugRequest() {
        state.videoDetailsOutput = null;
    },
    getVideosSlugSuccess(state, output) {
        state.videoDetailsOutput = output;
    },
    getVideosSlugFailure(state) {
        state.videoDetailsOutput = false;
    },
};


export const videos = {
    namespaced: true,
    state,
    actions,
    mutations
};