<template>
  <section>
    <nav class="navbar navbar-expand-lg navbar-light justify-content-between">
      <a href="/"><img class="logo-style" src="../assets/Header/logo.png" alt=""></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li>
            <router-link class="nav-link" :to="{name: 'Categories'}">{{$t("header.stand")}}</router-link>
          </li>
          <li>
            <router-link class="nav-link" :to="{name: 'Presentations'}">{{$t("header.presentation")}}</router-link>
          </li>
          <li>
            <router-link class="nav-link" :to="{name: 'Meetings'}">{{$t("header.meetings")}}</router-link>
          </li>
          <li class="dropdown">
            <a class="cursor-pointer">{{$t("header.doorgate")}}</a>
            <ul class="dropdown-content">
              <li class="w-100"><a class="text-left cursor-pointer" @click="sendClicked(1)">{{$t("header.contacts")}}</a></li>
              <li class="w-100"><a class="text-left cursor-pointer" @click="sendClicked(2)">{{$t("header.downloads")}}</a></li>
              <li class="w-100"><a class="text-left cursor-pointer" @click="sendClicked(3)">{{$t("header.projectSheet")}}</a></li>
            </ul>
          </li>
          <li class="dropdown d-flex align-items-center" id="dropdown">
            <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
              {{ $i18n.locale.toUpperCase() }}
            </button>
            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
              <li class="w-100">
                <a href="#" @click.prevent="setLocale('en')" class="dropdown-item text-left">
                  <span class="ml-2 h-8 w-8">English</span>
                </a>
              </li>
              <li class="w-100">
                <a href="#" @click.prevent="setLocale('pt')" class="dropdown-item text-left">
                  <span class="ml-2">Portuguese</span>
                </a>
              </li>
              <li class="w-100">
                <a href="#" @click.prevent="setLocale('es')" class="dropdown-item text-left">
                  <span class="ml-2">Spanish</span>
                </a>
              </li>
              <li class="w-100">
                <a href="#" @click.prevent="setLocale('fr')" class="dropdown-item text-left">
                  <span class="ml-2">French</span>
                </a>
              </li>
              <li class="w-100">
                <a href="#" @click.prevent="setLocale('it')" class="dropdown-item text-left">
                  <span class="ml-2">Italian</span>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <router-link v-if="!loggedIn" class="nav-link" :to="{name: 'Login'}">{{$t("header.login")}}</router-link>
            <a v-else @click="logoutClicked()" class="nav-link" href="/">{{$t("header.logout")}}</a>
          </li>
        </ul>

      </div>
      <!-- <img class="chat-style" src="../assets/Header/chat.png" alt=""> -->
    </nav>
    <div class='layout'>
      <SocialChat
        icon
        :attendants="attendants"
      >
        <p slot="header">{{$t("chat.header")}}</p>
        <small slot="footer">{{$t("chat.openingHours")}}</small>
      </SocialChat>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
const loggedIn = localStorage.getItem('user');
export default {
  data() {
    return {
      loggedIn: loggedIn,
      attendants: [
      {
        app: 'whatsapp',
        label: 'Technical support',
        name: 'Doorgate Assistant',
        number: '(+351) 244 810 600',
        avatar: {
          src: 'https://avatars0.githubusercontent.com/u/8084606?s=460&u=20b6499a416cf7129a18e5c168cf387e159edb1a&v=4',
          alt: 'Alan Ktquez avatar'
        }
      },
      // ...
    ]
    }
  },
  methods: {
    ...mapActions('account', ['logout']),
    sendClicked (id) {
      this.$router.push({name: 'StaticPage', query: {id: id} });
    },
    logoutClicked(){
       this.logout()
       this.$router.go();
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem('locale', locale);
      this.$router.go();
    },
  },
}
</script>

<style scoped>
.navbar {
  min-height: 85px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  display: inline-block;
  color: var(--secondary-color);
  font-size: 18px;
}

.logo-style{
  width: 200px;
}


.navbar-light .navbar-nav .nav-link {
  display: block;
  color: var(--secondary-color);
  padding: 14px 16px;
  text-decoration: none;
}

.nav-link:hover {
  color: var(--primary-color);
}


.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 2;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}
#dropdownMenuButton{
  color: var(--secondary-color);
}
li a {
  display: block;
  color: var(--secondary-color);
  padding: 14px 16px;
  text-decoration: none;
}

li a:hover {
  color: var(--primary-color);
}
.router-link-active,
.router-link-exact-active {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}

.dropdown-item:active{
  background-color: #e9ecef;
  color: var(--primary-color);
}

.btn:focus, .btn.focus {
  box-shadow: none;
}
/deep/.vsc-popup-button--default{
  background-color: var(--primary-color);
}
/deep/.vsc-popup-button:focus, 
/deep/.vsc-popup-button:hover {
  box-shadow: 0 0 0 3px #fff,0 0 0 6px var(--primary-color);
}

@media only screen and (max-width: 930px) {
 
  /* #dropdown > ul > li:nth-child(1) > a > span{
    color: var(--secondary-color);
  }
  #dropdown > ul > li:nth-child(2) > a > span{
    color: var(--secondary-color);
  } */
}
</style>