<template>
  <section>
    <SpinnerLoading v-if="categoriesOutput == null"/>
    <div class="container-fluid" v-else>
        <div class="row">
           <div class="col-12 col-md-8">
              <img class="w-100" src="../assets/institutional.gif" alt="">
            </div>
            <div class="col-12 col-md-4 d-flex flex-column">
              <span class="categories-title my-3 mx-3">{{$t("categories")}}</span>
              <br>
              <span class="mx-3" v-if="categoriesList && categoriesList.length == 0">{{$t("noResults")}}</span>
              <ul class="no-marker" v-for="(categorie, index) in categoriesList" :key="index">
                <a @click="sendClick(index)">
                  <li>
                    <img v-if="categorie.parent == null && categorie.title != null" src="../assets/stand/hotspots.svg" alt="">
                    <span v-if="categorie.parent == null && categorie.title != null" class="mx-3">{{categorie.title}}</span>
                  </li>
                </a>
              </ul>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import SpinnerLoading from "@/components/SpinnerLoading.vue";
import { API_HELPER } from "@/helpers/api.js";
import { mapState, mapActions } from "vuex";
import InputGetList from "@/classes/InputGetList";
export default {
  components:{
    SpinnerLoading
  },
  data() {
    return {
      routeId: this.$route.query.id,
    }
  },
  computed: {
    ...mapState({
        categoriesOutput: state => state.categories.listOutput,
    }),
    categoriesList: function() {
      return this.categoriesOutput && this.categoriesOutput.data ? this.categoriesOutput.data : [];
    },
  },
  methods: {
    ...mapActions({
        getCategories: 'categories/getList',
    }),
    invokeGetInfo()
    {
        const itemsPerPage = 999;
        const currentPage = 1;
        const searchText = '';
        const sorter = 2; // desc
        const propertyName = "id";

        var input = new InputGetList(itemsPerPage, currentPage, searchText, sorter, propertyName);
        // List specific parameters
        // input.visible= true;
        this.getCategories(input);
    },
    sendClick(index){
      this.$router.push({name: "SubCategories", query: {id: this.categoriesList[index].id}, params: {image: this.getImageCategory(index), video: this.getImageVideo(index) } });
    },
    getImageCategory(index)
    {
      return this.categoriesList[index] && this.categoriesList[index].photoPath ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.categoriesList[index].photoPath]) : null;
    },
    getImageVideo(index)
    {
      return this.categoriesList[index] && this.categoriesList[index].videoUrl ? this.categoriesList[index].videoUrl  : null;
    },
  },
  mounted() {
     // Get info from API
    this.invokeGetInfo(this.routeId);
  },
}
</script>

<style scoped>
a{
  text-decoration: none !important;
  color: black !important;
  cursor: pointer;
}
img
{
  object-fit: contain;
  max-height: 50vh;
}
.no-marker {
  list-style-type: none;
}
.categories-title{
  font-size: 30px;
  font-weight: bold;
}
</style>