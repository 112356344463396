import { USER_SERVICE } from "@/services/user.service.js";
import router from '@/router/index';
import store from '@/store/index.js'
import { API_HELPER } from "@/helpers/api.js";

const user = JSON.parse(localStorage.getItem('user'));
const state = user
    ? { status: { loggedIn: true }, user , reset: undefined, update: null, processTask: undefined, refresh: undefined}
    : { status: {}, user: null , reset: undefined, update: null, processTask: undefined, refresh: undefined};

const actions = {
    login({ dispatch, commit }, { email, password }) {
        dispatch('alert/clear', null, { root: true });
        commit('loginRequest', { email });
        
        USER_SERVICE.login(email, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    window.location.href= '/'; 
                },
                error => {
                    commit('loginFailure', user);                
                    dispatch('alert/error', error, { root: true });

                }

            );
    },
    signup({ dispatch, commit }, { firstName, lastName, email, phoneNumber, companyName, companyAddress, companyZipCode, companyCity, companyCountry, companyWebsite }) {
        dispatch('alert/clear', null, { root: true });
        commit('signupRequest');
        
        USER_SERVICE.signup({ firstName, lastName, email, phoneNumber, companyName, companyAddress, companyZipCode, companyCity, companyCountry, companyWebsite })
            .then(
                user => {
                    commit('signupSuccess', user);
                    dispatch('alert/success', user, { root: true });

                },
                error => {
                    commit('signupFailure', user);                
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    requestLink({ dispatch, commit }, email) {
        dispatch('alert/clear', null, { root: true });
        commit('linkRequest');

        USER_SERVICE.requestLink(email)
            .then(
                user => {
                    commit('linkSuccess',user);
                    dispatch('alert/success', user, { root: true });
                },
                error => {
                    commit('linkFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    resetPassword({ dispatch, commit }, {username, token, password }) {
        dispatch('alert/clear', null, { root: true });
        commit('resetPasswordRequest');

        USER_SERVICE.resetPassword(username, token, password )
            .then(
                user => {
                    commit('resetPasswordSuccess', user);
                    dispatch('alert/success', user, { root: true });
                },
                error => {
                    commit('resetPasswordFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    confirmAccount({ dispatch, commit }, {password, username, token}) {
        dispatch('alert/clear', null, { root: true });
        commit('confirmAccountRequest');
        USER_SERVICE.confirmAccount(password, username, token)
        .then(
            user => {
                commit('confirmAccountSuccess', user);
                dispatch('alert/success', user, { root: true });
            },
            error => {
                commit('confirmAccountFailure', error);
                dispatch('alert/error', error, { root: true });
            }
        );
    },
    // refreshToken({ dispatch, commit }) {
    //     dispatch('alert/clear', null, { root: true });
    //     commit('refreshRequest');
        
    //     /* Refresh the token and try again */
    //     USER_SERVICE.refreshToken(state.user.data.refreshToken, state.user.data.profile.email)
    //     .then(user =>{
    //         commit('refreshSuccess', user);
    //    })
    //    .catch((error) => 
    //    {
    //         commit('refreshFailure', error);
    //         dispatch('alert/error', error, { root: true });
    //         dispatch('account/logout', null, { root: true });
    //    });
    // },
    logout({ dispatch, commit }) {
        dispatch('alert/clear', null, { root: true });
        dispatch('favorites/clear', null, { root: true });
        dispatch('cart/clear', null, { root: true });

        commit('logout');

        USER_SERVICE.logout();
    }
};

const mutations = {
    /* Login */
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggedIn: true };
        state.user = user;

        // uncomment if you want to test the token with a set expiration date
        // var currentDate = new Date();
        // user.data.accessTokenExpirationDate = new Date(currentDate.getTime() + 3*60000);

        // login successful if there's a jwt token in the response
        if (user.data && user.data.refreshToken) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
        }

    },
    loginFailure(state) {
        state.status = null;
        state.user = null;
    },
    /* Signup */
    signupRequest(state)
    {
        state.status = { registering: true };
    },
    signupSuccess(state)
    {
        state.status = {registered: true};
    },
    signupFailure(state)
    {
        state.status = null;
    },
     /* Request link */
    linkRequest(state) {
        state.reset = null;
    },
    linkSuccess(state) {
        state.reset = true;
    },
    linkFailure(state) {
        state.reset = false;
    },
     /* Reset Password */
    resetPasswordRequest(state) {
        state.processTask = null;
    },
    resetPasswordSuccess(state) {
        state.processTask = true;
    },
    resetPasswordFailure(state) {
        state.processTask = false;
    },
    /* Confirm Account */
    confirmAccountRequest(state) {
        state.processTask = null;
    },
    confirmAccountSuccess(state) {
        state.processTask = true;
    },
    confirmAccountFailure(state) {
        state.processTask = false;
    },
    /* */
      /* refreshToken */
    //   refreshRequest(state) {
    //     state.refresh = 'refreshing';
    // },
    // refreshSuccess(state, user) {
    //     state.user = user;
    //     // login successful if there's a jwt token in the response
    //     if (user && user.data && user.data.refreshToken) {
    //         // store user details and jwt token in local storage to keep user logged in between page refreshes
    //         localStorage.setItem('user', JSON.stringify(user));
    //     }

    //     state.refresh = true;
    // },
    // refreshFailure(state) {
    //     state.refresh = false;
    // },
    /* */
    logout(state) {
        state.status = {};
        state.user = null;

        localStorage.removeItem('user');
        localStorage.removeItem('locale');
        
        router.go('/Login'); // Force all modules to reset state
    }
};

export const account = {
    namespaced: true,
    state,
    actions,
    mutations
};