/* Imports */
import { API_HELPER } from "@/helpers/api.js";
import { ENVIRONMENT_HELPER } from "@/helpers/environment.js";
// import { authHeader } from "@/helpers/auth-header.js";

/* Exports */
// API services that can be invoked on the modules
export const USER_SERVICE = {
    login,
    signup,
    requestLink,
    resetPassword,
    confirmAccount,
    // refreshToken,
    logout
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "Users";

/* Services */
function login(username, password) 
{
    // Mandatory parameters
    if(!username || !password)
    {
        return Promise.reject("The parameters are not complete!");
    }

    //

    var raw = JSON.stringify({"username": username, "password": password});

    const requestOptions = {
        method: 'POST',
        headers: {"Content-Type":"application/json"},
        body: raw
    };

    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "Login"]);
    
    return processLogin(url, requestOptions);
}

function processLogin(url, requestOptions)
{
    return fetch(encodeURI(url), requestOptions)
    .then(response =>{
        if(ENVIRONMENT_HELPER.isDev()) console.log(response);

        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if(ENVIRONMENT_HELPER.isDev()) console.log(data);

            if (!response.ok) {
                return Promise.reject(data);
            }
    
            var user = data;

            // login successful if there's a jwt token in the response
            if (user.data.accessToken) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }
        
            return user;
        });        
    });
}
function signup(input)
{
    // Mandatory parameters
    if(!input || !Object.values(input).some(v => v))
    {
        return Promise.reject("The parameters are not complete!");
    }

    //

    const requestOptions = {
        method: 'POST',
        headers: {"Content-Type":"application/json"},
        body: JSON.stringify(input)
    };

    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);
    // Process API call
    return API_HELPER.apiCall(url, null, requestOptions);
}
function requestLink(email) 
{
    // Mandatory parameters
    if(!email)
    {
        return Promise.reject("The parameters are not complete!");
    }

    //

    const requestOptions = {
        method: 'GET',
    };

    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "Reset", email]);
    
    return API_HELPER.apiCall(url, null, requestOptions);
}
function resetPassword(username, token, password ) 
{
    // Mandatory parameters
    if(!username || !token || !password )
    {
        return Promise.reject("The parameters are not complete!");
    }

    //

    var raw = JSON.stringify({username, token, password });
    const requestOptions = {
        method: 'PUT',
        headers: { "Content-Type":"application/json" },
        body: raw
    };

    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "Reset"]);

    return API_HELPER.apiCall(url, null, requestOptions);    
}
function confirmAccount(password, username, token) 
{
    // Mandatory parameters
    console.log("service")
    console.log(password)
    console.log(username)
    console.log(token)
    if(!password || !username || !token)
    {
        return Promise.reject("The parameters are not complete!");
    }

    //
    var raw = JSON.stringify({"password": password, "username": username, "token": token});

    const requestOptions = {
        method: 'PUT',
        headers: {"Content-Type":"application/json"},
        body: raw
    };

    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "ConfirmAccount"]);
    
    return API_HELPER.apiCall(url, null, requestOptions);
}
// function refreshToken(token, userEmail)
// {
//     // Mandatory parameters
//     if(!token || !userEmail)
//     {
//         return Promise.reject("The parameters are not complete!");
//     }

//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({token, userEmail})
//     };

//     var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "refresh"]);

//     return API_HELPER.apiCall(url, null, requestOptions);
// }
function logout() {
    // TODO Integrate logout service
    return true;
}