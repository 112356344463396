import Vue from "vue";
import VueRouter from "vue-router";

import Categories from "../views/Categories.vue";
import SubCategories from "../views/SubCategories.vue";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import SetPassword from "../views/SetPassword.vue";
import Meetings from "../views/Meetings.vue";
import Presentations from "../views/Presentations.vue";
import PresentationDetails from "../views/PresentationDetails.vue";
import ProductDetails from "../views/ProductDetails.vue";
import Products from "../views/Products.vue";
import StaticPage from "../views/StaticPage.vue";
// import i18n from '@/helpers/i18n';

import AppHeader from "../layout/AppHeader.vue";
import AppFooter from "../layout/AppFooter.vue";

import Page404 from "../views/Page404.vue";

// import { CONSTANTS } from '@/helpers/consts';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    components: {
      header: AppHeader,
      default: Home,
      footer: AppFooter
    }
  },
  {
    // path: i18n.t('vueRoutes.blog'),
    // alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.blog', locale.key) ),
    path: '/Categories',
    name: 'Categories',
    components: {
      header: AppHeader,
      default: Categories,
      footer: AppFooter
    }
  },
  {
    // path: i18n.t('vueRoutes.blog'),
    // alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.blog', locale.key) ),
    path: '/SubCategories',
    name: 'SubCategories',
    components: {
      header: AppHeader,
      default: SubCategories,
      footer: AppFooter
    }
  },
  {
    // path: i18n.t('vueRoutes.blog'),
    // alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.blog', locale.key) ),
    path: "/Login",
    name: 'Login',
    components: {
      header: AppHeader,
      default: Login,
      footer: AppFooter
    }
  },
  {
    // path: i18n.t('vueRoutes.blog'),
    // alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.blog', locale.key) ),
    path: "/SetPassword",
    name: 'SetPassword',
    components: {
      header: AppHeader,
      default: SetPassword,
      footer: AppFooter
    }
  },
  {
    // path: i18n.t('vueRoutes.blog'),
    // alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.blog', locale.key) ),
    path: "/Meetings",
    name: 'Meetings',
    components: {
      header: AppHeader,
      default: Meetings,
      footer: AppFooter
    }
  },
  {
    // path: i18n.t('vueRoutes.blog'),
    // alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.blog', locale.key) ),
    path: "/Presentations",
    name: 'Presentations',
    components: {
      header: AppHeader,
      default: Presentations,
      footer: AppFooter
    }
  },
  {
    // path: i18n.t('vueRoutes.blog'),
    // alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.blog', locale.key) ),
    path: "/PresentationDetails/:slug",
    name: 'PresentationDetails',
    components: {
      header: AppHeader,
      default: PresentationDetails,
      footer: AppFooter
    }
  },
  {
    // path: i18n.t('vueRoutes.blog'),
    // alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.blog', locale.key) ),
    path: "/ProductDetails/:slug",
    name: 'ProductDetails',
    components: {
      header: AppHeader,
      default: ProductDetails,
      footer: AppFooter
    }
  },
  {
    // path: i18n.t('vueRoutes.blog'),
    // alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.blog', locale.key) ),
    path: "/Products",
    name: 'Products',
    components: {
      header: AppHeader,
      default: Products,
      footer: AppFooter
    }
  },
  {
    // path: i18n.t('vueRoutes.blog'),
    // alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.blog', locale.key) ),
    path: "/Page",
    name: 'StaticPage',
    components: {
      header: AppHeader,
      default: StaticPage,
      footer: AppFooter
    }
  },
  {
    path: '*',
    name: '404',
    components: {
      header: AppHeader,
      default: Page404,
      footer: AppFooter
    }
  },    
  // {
  //   path: '/logout',
  //   name: 'Logout',
  //   component: Logout
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
