<template>
  <section>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <!-- Calendly inline widget begin -->
          <div class="calendly-inline-widget" data-url="https://calendly.com/d/z88-3k3-dxz/30min?hide_gdpr_banner=1" style="min-width:320px;height:700px;"></div>
          <!-- Calendly inline widget end -->
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  mounted() {
    
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.head.appendChild(script);

  },
};
</script>

<style>
</style>
