/* The application supports multiple languages. 
It will recognize the browser language and use it as long as it is one of the supported ones. In case it is not supported, the default locale is 'en'.
To support a new language, simply add a json file with the translations to the resources folder and the locale as the name.
For further explanation, on plurization and text with parameters, please check https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-vue-app-with-vue-i18n. */
 
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {};
function loadLocaleMessages () 
{
    if(Object.entries(messages).length === 0)
    {
        const locales = require.context('./resources', true, /[A-Za-z0-9-_,\s]+\.json$/i);
        locales.keys().forEach(key => 
            {
                const matched = key.match(/([A-Za-z0-9-_]+)\./i);
                if (matched && matched.length > 1) 
                {
                    const locale = matched[1];
                    messages[locale] = locales(key);
                }
            });
    }

    return messages;
}
 
export default new VueI18n(
    {
        locale: localStorage.getItem('locale') || 'en',
        fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
        messages: loadLocaleMessages()
    }
);