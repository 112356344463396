<template>
  <section>
      <SpinnerLoading v-if="videosOutput == null"/>
    <div class="container-fluid" v-else>
      <div class="row ">
          <div class="col-12 d-flex justify-content-center">
              <span class="title-style">{{$t("presentation.watchThe")}} <span class="doorgate-title-style">{{$t("presentation.thematicPresentations")}}</span></span>
          </div>
      </div>
      <div class="row cols-align">
        <div class="grid-item col-sm-12 col-md-6 col-lg-4 my-2" v-for="(video, index) in videosList" :key="index">
          <video width="100%" controls>
            <source :src="video.videoUrl" type="video/mp4">
          Your browser does not support the video tag.
          </video>
          <div class="d-flex justify-content-between my-3">
            <span>{{video.title}}</span>
            <!-- <span>1:03</span> -->
          </div>
          <div class="d-flex justify-content-center">
            <a @click="sendClicked(index)" class="btn btn-style">{{$t("learnMore")}}</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SpinnerLoading from "@/components/SpinnerLoading.vue";
import { mapState, mapActions } from "vuex";
import InputGetList from "@/classes/InputGetList";
export default {
  components:{
    SpinnerLoading
  },
  computed: {
    ...mapState({
        videosOutput: state => state.videos.listOutput,
    }),
    videosList: function() {
      return this.videosOutput && this.videosOutput.data ? this.videosOutput.data : [];
    },
  },
  methods: {
    ...mapActions({
        getvideos: 'videos/getList',
    }),
    invokeGetInfo()
    {
        const itemsPerPage = 999;
        const currentPage = 1;
        const searchText = '';
        const sorter = 1; // desc
        const propertyName = "title";

        var input = new InputGetList(itemsPerPage, currentPage, searchText, sorter, propertyName);
        // List specific parameters
        // input.visible= true;
        this.getvideos(input);
    },
    sendClicked(index){
      this.$router.push({name: "PresentationDetails",  params:{ slug: this.videosList[index].slug} });
    },
  },
  mounted() {
     // Get info from API
    this.invokeGetInfo();
  },
}
</script>

<style scoped>
.cols-align{
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}
.video-col{
  display: flex;
  justify-content: center;
  height: 300px;
}

.grid-item {
    position: relative;
}
.img-presentation{
  width: 100%;
  height: 300px;
  /* position: absolute; */
}
.title-style{
  word-break: break-word;
  color: gray;
  font-size: 52px;
}
.doorgate-title-style{
  color: var(--primary-color);
  font-weight: bold;
  font-size: 52px;
  word-break: break-word;
}
.btn-style{
  width: 150px;
  height: 50px;
  color: white;
  background-color: var(--primary-color);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}
@media only screen and (max-width: 520px) {
  .title-style{
  font-size: 27px;
}
.doorgate-title-style{
  font-size: 27px;
}
}
</style>