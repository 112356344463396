<template>
  <section>
      <SpinnerLoading v-if="videoDetailsOutput == null"/>
    <div class="container-fluid" v-else>
        <div class="row">
            <div class="col-12 col-md-8">
              <!-- <button class="btn btn-orange-style" @click="showVideo = true">click here</button> -->
              <!-- <img @click="showVideo = true" class="cursor-pointer w-100 img-style" src="../assets/home/home.jpg" alt=""> -->
              <iframe width="100%" height="700px" :src="embedLink(videoUrlPath) + '?rel=0'" title="YouTube video player" controls=0 frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="col-12 col-md-4 col-content-style">
                <span class="title">{{videoDetailsOutput.data.title}}</span>
                <span class="short-desc" v-html="videoDetailsOutput.data.description"></span>
                <!-- <span class="desc">{{videoDetailsOutput.data.longDescription}}</span> -->
                <div class="buttons-align">
                  <a v-if="pdfFilePath" :href="pdfFilePath" target="_blank" class="btn btn-orange-style">{{$t("downloadBtn")}}</a>
                  <button @click="sendClick()" class="btn btn-gray-style">{{$t("scheduleBtn")}}</button>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import SpinnerLoading from "@/components/SpinnerLoading.vue";
import { API_HELPER } from "@/helpers/api.js";
import { mapState, mapActions } from "vuex";
import router from "@/router";
export default {
  components:{
    SpinnerLoading
  },
  data() {
    return {
      showVideo: false,
    }
  },
    methods: {
    ...mapActions({
      getSlug: 'videos/getDetail',
    }),
    invokeGetSlug()
    {
      if (!this.$route.params.slug) {
        router.push({ name: "Presentations" });
        }
        else{
          this.getSlug(this.$route.params.slug);
        }
    },
    sendClick() {
      this.$router.push("/Meetings")
    },
        // SAMEORIGIN problem - If you are getting this error for a YouTube video, rather than using the full url use the embed url.
    embedLink: function (link) {
      let genericUrl = "https://www.youtube.com/watch?v=";
      let replaceUrl = "https://www.youtube.com/embed/";

      if(link && genericUrl){
          return link.replace(genericUrl, replaceUrl); 
      }else{
          return link;
      }
    },
  },
  computed: {
    ...mapState({
      videoDetailsOutput: state => state.videos.videoDetailsOutput,
    }),
    videoUrlPath: function()
    {
      return this.videoDetailsOutput.data && this.videoDetailsOutput.data.videoUrl ? this.videoDetailsOutput.data.videoUrl : null;
      // return this.videoDetailsOutput.videoUrl ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.videoDetailsOutput.videoUrl]) : null;
    },
    pdfFilePath: function()
    {
      // return this.videoDetailsOutput.data && this.videoDetailsOutput.data.pdfFile ? this.videoDetailsOutput.data.pdfFile : null;
      return this.videoDetailsOutput.data && this.videoDetailsOutput.data.pdfFile ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.videoDetailsOutput.data.pdfFile]) : null;
    },
  },
  mounted: function()
  {
      // GET list of productSlug
      this.invokeGetSlug();
  },
  watch: {
    $route(to) {
      if(to.name == "PresentationDetails")
        {
            this.invokeGetSlug();
        }
    },
  },
}
</script>

<style scoped>
.btn-orange-style{
  width: 200px;
  height: auto;
  color: white;
  background-color: var(--primary-color);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}
.btn-gray-style{
  width: 150px;
  height: auto;
  color: white;
  background-color: var(--secondary-color);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}
.col-content-style{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  justify-content: center;
}
.buttons-align{
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
}
.title{
  font-size: 30px;
  color: var(--primary-color);
  font-weight: bold;
}
.short-desc{
  font-size: 25px;
}
.desc{
  font-size: 15px;
}
.img-style{
    height: 100%;
}
@media only screen and (max-width: 767px) {
  .col-content-style{
    margin-top: 2rem;
  }
}
@media only screen and (max-width: 505px) {
  .buttons-align{
    justify-content: center;
  }
}
</style>