/* Imports */
import { API_HELPER } from "@/helpers/api.js";
import { authHeader } from "@/helpers/auth-header.js";

/* Exports */
// API services that can be invoked on the modules
export const VIDEOS_SERVICE = {
    list,
    getDetail
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "Videos";

/* Services */
function list(queryParams)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);    

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    }; 

    // Process API call, validating the token beforehand.
    return API_HELPER.apiCall(url, queryParams, requestOptions);
}
function getDetail(slug)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, slug]);    

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    }; 

    // Process API call, validating the token beforehand.
    return API_HELPER.apiCall(url, null, requestOptions);
}