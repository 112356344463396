// Operations according to the current environment (development or production)

export const ENVIRONMENT_HELPER = {
    isProd,
    isDev
};

function isProd()
{
    return process.env.NODE_ENV === 'production';
}

function isDev()
{
    return process.env.NODE_ENV === 'development';
}