<template>
  <div class="d-flex flex-column" id="app">
    <router-view class="header-style p-3 p-md-5" name="header"></router-view>
    <router-view class="container-style flex-grow-1 p-3 p-md-5" ref="container"/>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>

export default{
  data: function () {
    return {
    };
  },
  mounted: function()
  {
  },
  computed:
  {
  },
  methods:
  {
  }
}
</script>


<style>
@import './assets/fonts.css';
/* Structure */
html, body, #app
{
  height: 100vh;
  max-width: 100vw;
  background-color: #F9F9FB !important;
  font-family: 'Manrope', sans-serif!important;
}
:root{
    --primary-color: #ED7124;
    --secondary-color: #AEAEB2;
}
.cursor-pointer
{
  cursor: pointer;
}
.text-right
{
  text-align: right;
}
.text-center
{
  text-align: center;
}
.text-left
{
  text-align: left;
}
.disabled
{
  pointer-events: none;
  opacity: 0.5;
}
.hidden
{
  visibility: hidden;
}
</style>