import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import i18n from '@/helpers/i18n';
import { API_HELPER } from "@/helpers/api.js";
import store from "./store";
import VueSocialChat from 'vue-social-chat'

Vue.config.productionTip = false
Vue.use(VueSocialChat)

const options = {
  locale: i18n.locale,
};
Vue.use(options);

// vuelidate
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

// SimpleAlert
import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueSimpleAlert);

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// Scroll to top on route change
router.beforeEach((to, from, next) => {
  console.log(to);
  console.log(from);

  // cancel pending requests and reset controller
  API_HELPER.abortController.abort();
  API_HELPER.abortController = new AbortController();
  
  // Scroll to top between each route change
  window.scrollTo(0, 0);
  // Block logged in only pages pages
  const loggedIn = localStorage.getItem('user');
  const publicPages = [ '/', '/Login', '/SetPassword' ];
  const authRequired = !publicPages.includes(to.path);

  if (authRequired && !loggedIn) {
    next('/Login');
  }
  next();
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
