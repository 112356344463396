<template>
  <section id="page">
    <SpinnerLoading v-if="detailPage == null"/>
    <div class="container-fluid" v-else>
      <div class="info">
        <img v-if="photo" :src="photo" class="w-100 top-bg" alt="Image">
        <div class="title">
          <span class="detailPage-title">{{ detailPage.title }}</span>
        </div>
        <div class="description">
          <span v-html="detailPage.content"></span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SpinnerLoading from "@/components/SpinnerLoading.vue";
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import { API_HELPER } from "@/helpers/api.js";

export default {
  components:{
    SpinnerLoading
  },  
  mounted() 
  {
    this.initializePage();
  },
  computed: 
  {
    ...mapState("staticPages", ["detailOutput"]),
    detailPage: function () {
      return this.detailOutput && this.detailOutput.data ? this.detailOutput.data : null;
    },
    photo: function()
    {
      return this.detailPage && this.detailPage.image ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.detailPage.image]) : null;
    },
  },
  methods:
  {
    ...mapActions('staticPages', ['getDetailById']),

    initializePage()
    {
      if (!this.$route.query.id) {
        router.push({ name: "Home" });
      }else{
        this.getDetailById(this.$route.query.id);
       }
    }
  },
  watch: {
    $route(to) {
      if(to.name)
      {
        this.initializePage();
      }
    },
  }
}
</script>

<style scoped>
.info{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.detailPage-title{
  color: var(--primary-color);
  font-size: 30px;
  font-weight: bold;
}
.title{
  margin-bottom: 3rem;
}

.top-bg
{
  object-fit: contain;
}
</style>