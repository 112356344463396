<template>
  <section>
    <SpinnerLoading v-if="productDetail == null"/>
    <div class="container-fluid" v-else>
        <div class="row">
            <div class="col-12 col-lg-4 col-content-style">
                <span class="title">{{productDetail.name}}</span>
                <span class="short-desc" v-html="productDetail.description"></span>
                <span class="desc" v-html="productDetail.excerpt"></span>
                <div class="buttons-align">
                  <a v-if="pdfFilePath" :href="pdfFilePath" target="_blank" class="btn btn-orange-style">{{$t("downloadBtn")}}</a>
                  <button @click="sendClick()" class="btn btn-gray-style">{{$t("scheduleBtn")}}</button>
                </div>
            </div>
            <div class="col-12 col-lg-8 col-content-style">
              <!-- Slider -->
                      <img class="w-100" :src="photo" alt="">
              <!-- <div class="col-12 mt-1 d-flex align-items-center" id="slide">
                <vueper-slides ref="productDetailSlider" :arrows="false" class="slider no-shadow w-100" fixed-height= "400px" :bullets="true" :bullets-outside="true" :visible-slides="1" :touchable="false" :dragging-distance="70" :slide-ratio="1">
                  <vueper-slide>
                    <template v-slot:content>
                      <img class="w-100" :src="productDetail.photoPath" alt="">
                    </template>
                  </vueper-slide>
                </vueper-slides>  
                <div class="arrows-div">
                  <img class="arrow-left" @click="$refs.productDetailSlider.previous()" src="../assets/home/arrow-left.svg" alt="" />
                  <img class="arrow-right" @click="$refs.productDetailSlider.next()" src="../assets/home/arrow-right.svg" alt="" />
                </div>
              </div> -->
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import SpinnerLoading from "@/components/SpinnerLoading.vue";
// import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { API_HELPER } from "@/helpers/api.js";
import { mapState, mapActions } from "vuex";
import router from "@/router";
export default {
  components:{
    // VueperSlides, 
    // VueperSlide,
    SpinnerLoading
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapActions({
      getSlug: 'products/getDetail',
    }),
    invokeGetSlug()
    {
      if (!this.$route.params.slug) {
        router.push({ name: "Product" });
        }
        else{
          this.getSlug(this.$route.params.slug);
        }
    },
    sendClick() {
      this.$router.push("/Meetings")
    },
  },
  computed: {
    ...mapState({
      productOutput: state => state.products.productOutput,
    }),
    productDetail: function()
    {
      return this.productOutput && this.productOutput.data ? this.productOutput.data : null;
    },
    pdfFilePath: function()
    {
      // return this.productDetail && this.productDetail.pdfFile ? this.productDetail.pdfFile : null;
      return this.productDetail.pdfFile ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.productDetail.pdfFile]) : null;
    },
    photo: function()
    {
      // return this.productDetail && this.productDetail.pdfFile ? this.productDetail.pdfFile : null;
      return this.productDetail.photoPath ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.productDetail.photoPath]) : null;
    },
  },
  mounted: function()
  {
      // GET list of productSlug
      this.invokeGetSlug();
  },
  watch: {
    $route(to) {
      if(to.name == "ProductDetails")
        {
            this.invokeGetSlug();
        }
    },
  },
}
</script>

<style scoped>
.btn-orange-style{
  width: 200px;
  height: 50px;
  color: white;
  background-color: var(--primary-color);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}
.btn-gray-style{
  width: 150px;
  height: 50px;
  color: white;
  background-color: var(--secondary-color);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}
.col-content-style{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
}
.buttons-align{
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.title{
  font-size: 30px;
  color: var(--primary-color);
  font-weight: bold;
}
.short-desc{
  font-size: 25px;
}
.desc{
  font-size: 15px;
}
img{
  object-fit: contain;
  max-height: 50vh;
}
#slide{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vueperslides--fixed-height.vueperslides--bullets-outside{
  margin-bottom: 1rem !important;
}
.arrows-div{
  gap: 1rem;
  display: flex;
  cursor: pointer;
}
.arrow-left{
  transform: rotate(180deg);
}
</style>