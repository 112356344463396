<template>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-12 d-flex flex-column align-items-center">
                    <span>Ops... You Are Lost!</span>
                    <br>
                    <button class="btn" @click="goHome">Go to Home</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'Page404',
    methods: 
    {
        goHome: function()
        {
            this.$router.push("/");
        }
    }
}
</script>

<style scoped>

</style>