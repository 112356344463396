<template>
    <section id="login">
        <div class="container d-flex flex-column justify-content-center">
            <div class="row">
                <div class="col-12 d-flex justify-content-center">
                    <h4> {{ $t("setPassword.setPasswordTitle") }}</h4>
                </div>
            </div>
            <!-- Form -->
            <form @submit.prevent="submitClicked">
                    <div class="col-12 form-group my-2">
                        <label for="password">{{ $t("login.pass") }}</label>
                        <input type="password" id="password" name="password" v-model="password" class="form-control" invalid-feedback="Password Inválida" :class="{ 'is-invalid': submitted && hasPasswordError }"/>
                        <div v-if="submitted && hasPasswordError" class="invalid-feedback">
                            <span v-if="!$v.password.required">{{ $t("login.passVal") }}</span>
                            <span v-if="errors.Password">{{errors.Password}}</span>
                            <span v-if="errors.PasswordTooShort">{{errors.PasswordTooShort}}</span>
                            <span v-if="errors.PasswordRequiresNonAlphanumeric">{{errors.PasswordRequiresNonAlphanumeric}}</span>
                            <span v-if="errors.PasswordRequiresDigit">{{errors.PasswordRequiresDigit}}</span>
                            <span v-if="errors.PasswordRequiresLower">{{errors.PasswordRequiresLower}}</span>
                            <span v-if="errors.PasswordRequiresUpper">{{errors.PasswordRequiresUpper}}</span>
                        </div>
                    </div>
                    <div class="col-12 form-group my-2">
                        <label for="confirmPassword">{{ $t("login.rpass") }}</label>
                        <input type="password" v-model="confirmPassword" id="confirmPassword" name="confirmPassword" class="form-control" :class="{ 'is-invalid': submitted && hasError('ConfirmPassword') }" />
                        <div v-if="submitted && hasError('ConfirmPassword')" class="invalid-feedback">
                            <span v-if="!$v.confirmPassword.required">{{ $t("login.rpassVal") }}</span>
                            <span v-else-if="!$v.confirmPassword.sameAsPassword">{{ $t("login.matchPass") }}</span>
                        </div>
                    </div>
                    <!-- <div class="row m-0 p-0">
                        <div class="d-flex justify-content-between">
                            <p class="cursor-pointer" id="recover-style">{{ $t("setPassword.setPasswordBtn") }}</p>
                        </div>
                    </div> -->
                    <div class="col-12 mb-5 text-center">
                        <button type="submit" class="btn btn-secondary" v-bind:class="{disabled: loading}" :style="{minWidth: '140px'}">
                            <span v-show="!loading">{{$t("setPassword.setPasswordBtn")}}</span>
                            <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
                        </button> 
                    </div>
            </form>
        </div>
    </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { UTILS_HELPER } from "@/helpers/utils.js"
import { required, email, sameAs, minLength } from 'vuelidate/lib/validators'
import { API_HELPER } from "@/helpers/api.js";

export default {
    data: function () {
        return {
            username: "",
            token: "",
            // form
            password: null,
            confirmPassword: null,
            submitted: false,
            loading: false,
        };
    },
    validations: {
            password: { required },
            confirmPassword: { required, sameAsPassword: sameAs('password') }
    },
    computed:
    {
        ...mapState({ 
        alertType: state => state.alert.type,
        alertOutput: state => state.alert.output
        }),
        ...mapState('account', ['processTask']),
        errors: function()
        {
            return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};   
        },
        hasPasswordError()
        {
            return this.$v[UTILS_HELPER.convertToCamelCase("Password")].$error || Object.prototype.hasOwnProperty.call(this.errors, "Password") || Object.prototype.hasOwnProperty.call(this.errors, "PasswordTooShort") || Object.prototype.hasOwnProperty.call(this.errors, "PasswordRequiresNonAlphanumeric") || Object.prototype.hasOwnProperty.call(this.errors, "PasswordRequiresDigit") || Object.prototype.hasOwnProperty.call(this.errors, "PasswordRequiresLower") || Object.prototype.hasOwnProperty.call(this.errors, "PasswordRequiresUpper");
        },
    },
    created: function (){
        this.username = this.$route.query.username;
        this.token = this.$route.query.token;
        this.mode = this.$route.query.mode;
    },
      methods: {
    ...mapActions('account', ['confirmAccount']),
    ...mapActions('account', ['resetPassword']),
    ...mapActions({
        clearAlert: 'alert/clear' 
    }),
    submitClicked: function()
    {
        this.setPasswordClicked();
    },
    setPasswordClicked: function()
    {
        this.clearAlert();
        this.submitted = true;
        // stop here if form is invalid
        this.$v.$touch();
        console.log(this.$v.$invalid, this.password, this.username, this.token)
        if (this.$v.$invalid) {
            return;
        }
    
        this.loading = true;
        const { password, username, token } = this;
        if(this.mode == 'reset'){
            this.resetPassword({ username, token, password});
        }else{
            this.confirmAccount({password, username, token});
        }
    },
    hasError(fieldName)
    {
      return this.$v[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
    }
  },
    watch:{
        alertType: function(val)
        {
            this.loading = val === null;
        },
        processTask: function(val)
        {
            if(val)
            {
                this.loading = false;
                this.$fire({
                    title: this.$t("setPassword.passwordSetted"),
                    // text: this.$t("login.forgotText"),
                    type: "success"
                })
                this.submitted = false;
                // this.email = null;
                this.$router.push({name: 'Login'})
            }
        }
    }
}
</script>

<style scoped>
.container{
    max-width: 500px!important;
    /* min-height: 100vh; */
}
.register-style{
    margin-right: 2rem;
}
.login-style{
    margin-left: 2rem;
}
.vertical-line{
    border-right: 2px solid var(--primary-color);
}
h4{
    color: var(--primary-color);
    font-weight: bold;
}
#recover-style{
    text-decoration: underline;
    color: var(--primary-color);
}
</style> 