<template>
  <section>
    <div class="footer">
      <div class="d-flex flex-column justify-content-center align-items-center footer-content py-2">
        <a href="/Page?id=3"><img class="logo-style mb-2" src="../assets/support.png" alt=""></a>
        <span class="footer-item">© 2023 DOORGATE® – All rights reserved</span>
      </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped>
.footer {
  background-color: gray;
  color: white;
  height: auto;
}

.footer-content {
  display: flex;
}

.footer-item {
  padding: 10px;
}

/* @media only screen and (max-width: 600px) {
  .footer-content {
    flex-wrap: wrap;
  }
  
  .footer-item {
    flex: 0 0 50%;
    text-align: center;
  }
} */
</style>